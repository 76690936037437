// apiConfig.js
//const API_BASE_URL = 'http://67.225.189.98:3001';
//const API_BASE_URL = 'http://67.225.189.98:3001';
//const API_BASE_URL = 'https://craddd-cx3n.onrender.com';
//const API_BASE_URL = 'http://localhost:3001';
//const API_BASE_URL = 'https://api.cradduleapi.com.ng';
//const API_BASE_URL = 'https://craddule.com/v1';
const API_BASE_URL = 'https://api.craddule.com';


export default API_BASE_URL;
